<template lang="pug">
div
  .layout--full-page
    #page-login.h-screen.flex.w-full.bg-img
      .vx-col.mx-auto.self-center
        vx-card.back-trasnp
          img.avatar2(
            src="@/assets/images/logo/logo_quick.png"
          )
          router-view
  TheFooter.center-bt
</template>
<script>
import TheFooter from "../components/TheFooter.vue"
export default {
  components: {
    TheFooter
  },
}
</script>
<style>
.center-bt {
  right: 20px;
  left: 20px;
  bottom: 0px;
  position: absolute;
  z-index: 9999;
}
.avatar2 {
  margin-bottom: 10px;
  border-radius: 6px;
  display: block !important;
  margin: 20px auto;
}
.back-trasnp {
  width: 410px;
  height: 435px;
  flex-grow: 0;
  border-radius: 10px;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
}
@media screen and (max-width:768px) and (orientation:portrait) {
  .back-trasnp{
    width: 344px;
    height: 450px;
    flex-grow: 0;
    margin: 0 0 138px;
    padding: 31px 23px 53px 24px;
    border-radius: 10px;
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.05);
    background-color: #ffffff;
  }
  .center-bt {
    right: 0px;
    left: 0px;
  }
}
</style>